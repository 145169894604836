var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "capital-module"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('GenproxPageHeading', {
    attrs: {
      "title": "Capital",
      "description": "Get your capital shareholder details"
    }
  }), _c('cap-filters', {
    attrs: {
      "select-available": true,
      "showSelect": _vm.showLePicker
    },
    on: {
      "filtersChange": _vm.applyFiltersEvent,
      "changeViewedFund": _vm.changeViewedFund,
      "changeViewedCompany": _vm.changeViewedCompany
    }
  })], 1)])]), _c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('cap-table', {
    ref: "capTable",
    attrs: {
      "show-page-number-options": false,
      "tableItems": _vm.tableData,
      "setTableBusyMutation": "capital/setCapitalTableBusy",
      "busy": _vm.isBusy,
      "getItemsAction": "capital/getItems"
    }
  })], 1)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }